.header-account {
    @include span-columns(12);
    background: color('blue', 'light');
    color: color('white');
    text-align: center;
    font-size: 13px;
    line-height: 50px;

    &.active {
        
        .icon-arrow {
            transform: rotate(180deg);
        }

    }

    span {
        font-size: 20px;          
    }

    span, .hello {
        display: inline-block;
        vertical-align: middle;
    }

    .hello {

        &.not-logged {
            text-transform: uppercase;
            padding-left: 5px;
        }

    }

    .icon-arrow {
        font-size: 10px;
        margin-left: 10px;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;        
    }

    .box {
        @include arrow();
        border-top: 1px solid color('yellow');
        color: color('gray', 'dark');
        padding: 20px 20px 0 20px;
        background: color('white');

        &.not-logged {
            padding: 0;
        }        

        a {
            display: block;
            margin: 13px 0;
            line-height: 24px;

            &:last-child {
                padding-bottom: 13px;
                margin-bottom: 0;
            }

            &.button {
                margin: 0;
            }

        }

    }

}