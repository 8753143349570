%menu {
	@include span-columns(12);	
	position: relative;
	line-height: 52px;
	font-size: 16px;
	border-bottom: 1px solid color('gray');

	.ico-menu {
		display: inline-block;
		vertical-align: middle;
		min-width: 30px;
	}

	.icon-arrow {
		position: absolute;
		font-size: 14px;
		padding: 20px 20px 20px 40px;
		right: 0;
		top: 0;
        -webkit-transition: all 0.2s;
        transition: all 0.2s; 

	    &.active {
	    	padding: 20px 40px 20px 20px;
	    	transform: rotate(180deg);
	    }

	}

}

%sub-menu-container {
	display: none;
	border-top: 1px solid color('blue');
	background: color('gray', 'light');
}

%sub-menu {
	font-size: 16px;
}

.nav-primary {

	li {
		
		a {
			display: block;
			padding: 0 20px;
		}

		&.level0 {
			@extend %menu;
		}

		ul {

			&.level0 {
				@extend %sub-menu-container;
			}

			li {

				&.level1 {
					@extend %sub-menu;
				}

			}

		}	

	}

}