.account-information {
	@include span-columns(12);
	margin: 0 0 40px 0;

	.change-password-button {

		label {
			padding: 11px 20px;
			font-size: 14px;

			&:before,
			&:after {
				display: none;
			}

		}

	}

	.buttons-set {
		@include span-columns(12);
	}

	#change-password-form {

		.field {
			@include span-columns(12);
		}
		
	}

}
