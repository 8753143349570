.input-box {
	position: relative;
	display: block;
	padding: $gapp;
	margin-bottom: 0;
	border-bottom: 1px solid color('gray');

	input {
		padding: 12px 10px;
		font-size: 13px;
	}

	button {
		position: absolute;
		right: 16px;
		top: 16px;
		padding: 0;
		font-size: 16px;
		background: color('blue', 'light');
		height: 30px;
		width: 35px;
		line-height: 34px;
	}

}