.bar {
	@include span-columns(12);
	@extend .vertical-scroll;
	line-height: 30px;
	background: color('gray', 'light');
	text-align: center;
	border-bottom: 1px solid rgba(color('gray'), 0.2);

	li {
		display: inline-block;
		vertical-align: middle;
		padding: 0 20px;
		text-transform: uppercase;
		border-right: 1px solid rgba(color('gray'), 0.2);

		&:last-child {
			border-right: 0;
		}

		span {
			color: color('blue', 'light');
			margin-right: 15px;
			font-size: 15px;
			vertical-align: middle;
		}

	}

}

.bar-notlogin {
	@include span-columns(12);
	text-align: center;

	li {
		@include span-columns(12);
		padding: 10px 40px;
		font-size: 12px;
		line-height: 16px;
		position: relative;
		border-bottom: 1px solid rgba(color('gray'), 0.2);

		&:last-child {
			border-bottom: 0;
		}

		strong {
			display: block;
			padding-top: 10px;
			font-size: 16px;
			font-weight: normal;
			text-transform: uppercase;
			color: color('blue');
		}

		img {
			display: block;
			margin: 20px auto;
			width: 30%;
		}

		a {
			margin: 20px 0;
			font-size: 14px;
			font-weight: normal;

			&:hover {
				color: color('white');
			}

		}

	}

}