.cms-style-guide {

	.row, .page-title {
		@include span-columns(12);
		padding: 0 $gapp;
		margin-bottom: 30px;
	}

	h1 {
		margin-top: 30px;
	}

	h2 {
		margin-bottom: 10px;
	}

	textarea {
		display: block;
		padding: 10px 5px;
		font-size: 12px;
		text-align: center;
		resize: none;
		border: none;
	}

	.col-1,
	.col-2 {
		@include span-columns(12);
	}

	.col-2 {
		float: right;
		margin-right: 0;
	}	

	.buttons {

		li {
			@include span-columns(12);
		}

	}

	.icons {

		li {
			@include span-columns(12);
			margin: 20px 10px;
			text-align: center;

			&:before {
				font-size: 30px;
			}

		}

	}

}