.gallery-container {
    @include span-columns(12);
    position: relative;

    .product-image {

        .gallery-image {
            width: 100%;
            margin: 0 auto;
        }

        .swiper-button-next,
        .swiper-button-prev {
            top: 50%;
            font-size: 24px;
        }

    }

    .more-views {
        @extend .no-display;
    }

}