.checkout-cart-index {

	.page-header {

		.menu {

			span {
				background: color('yellow');
			}

		}

	}

	.page-title {
		@include span-columns(12);
		margin: 20px 0 0 0;
		border-bottom: 1px solid rgba(color('gray'), 0.4);

		h1 {
			font-size: 16px;
			color: color('blue', 'light');
			font-weight: normal;
			padding-bottom: 10px;
		}

	}

	.add-to {
		@include span-columns(12);
		margin: 20px 0;

		.buybycart-suggestion {

			ul {
				@include span-columns(12);
				margin: 20px 0;
				padding: $gapp;
				background: color('gray', 'light');

				li {
					float: none;
					display: block;
					padding: 5px 0;
				}

			}

		}
					
	}

	.checkout-buttons {
		@include span-columns(12);
		margin: 20px 0;
		
		li {
			@include span-columns(12);
		}

	}

	.cart-empty {
		font-size: 12px;
		line-height: 16px;
		min-height: 200px;

		a {
			font-weight: bold;
			color: color('blue');
		}

	}

	@import "components/cart/items.scss";
	@import "components/cart/box.scss";
}