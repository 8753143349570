dl {
	@include span-columns(12);
	text-align: center;

	&:nth-child(1),
	&:nth-child(2) {
		@include span-columns(6);
		margin-right: 0;

		dt {
	    	min-height: 36px;
		}

		dd {
			margin-bottom: 40px;
		}

	}

	dt {
		text-transform: uppercase;
	    font-size: 14px;
	    line-height: 18px;
	    margin-bottom: 10px;
	}

	dd {
		color: color('white');
		margin-bottom: 10px;

		a {
			color: color('white');
			display: block;
			font-size: 12px;
			margin-bottom: 12px;
			line-height: 14px;
		}

	}

	&.social {

		dd {
			margin-bottom: 0;
		}
		
		a {
			font-size: 34px;
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;

			&:last-child {
				margin-right: 0;
			}

		}

	}

}

a {

	&:hover {
		color: rgba(color('white'), 0.6);
	}

}