.collateral-tabs {
	@include span-columns(12);
	margin-top: 20px;
}

h2 {
	@extend .no-display;
}

.product-attributes {

	ul {
		@include span-columns(6);
		@include omega(2n);
		
		li {
			margin-bottom: 30px;
			line-height: 16px;

			strong {
				display: block;
				font-size: 14px;
				font-weight: normal;
				text-transform: uppercase;
			}

		}

	}

}

.box-reviews {

	.heading {
		@include span-columns(12);
		background: color('blue', 'light');
		padding: 10px;
		color: color('white');
		text-align: center;
		text-transform: uppercase;

		strong {
			@include span-columns(12);
			font-size: 16px;
			padding: 5px 0;
		}

		span {
			@include span-columns(12);
			font-size: 14px;
			padding: 5px 0;
		}

		button {
			@include span-columns(12);
			margin-top: 5px;
			font-size: 14px;
			letter-spacing: initial;
		}

	}

	.reviews {
		@include span-columns(12);

		.item {
			float: left;
			width: 100%;
			padding: 10px;
			border-bottom: 2px solid color('gray', 'light');

			.info {	
				@include span-columns(12);
				padding: 10px 0;

				.title {
					display: block;
					margin-bottom: 10px;
					font-size: 18px;
					color: color('blue');
				}

				.description {
					line-height: 18px;
				}

				.name {
					display: block;
					margin-top: 10px;
					font-size: 14px;

					strong {
						color: color('blue', 'light');
					}	

				}

			}

			.review {
				@include span-columns(12);
				padding: 10px;
				background: rgba(color('gray', 'light'), 0.5);
				font-size: 14px;

				p {
					display: block;
					margin-bottom: 10px;
					font-size: 16px;
					color: color('blue');
				}

				li {
					@include span-columns(12);
					padding: 5px 0;

					.rating-box {
						float: right;
					}

				}

			}

		}

	}

	.more-reviews {
		@include span-columns(12);
		margin-top: 20px;
		text-align: center;

		button {
			font-size: 16px;
			display: block;
			width: 30%;
			margin: 0 auto;
			background: color('blue', 'light');
		}

	}

}