.count {
	@extend .no-display;
}

.block-title {
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	padding: 20px;

	.closed {
		position: absolute;
		right: 0;
		top: 0;
	}
	
}

.result {
	position: relative;
	border-right: 1px solid color('gray', 'light');
	padding: 30px 0;

	li {
		font-size: 18px;
		padding: 15px 0;
		display: block;
		position: relative;
		padding-right: 50px;

		.btn-remove {
			position: absolute;
			right: 20px;
			top: 4px;
			font-size: 14px;
			color: color('blue');
			font-weight: bold;
			padding: 10px;
		}

		.label {
			font-weight: bold;
		}

	}

}

dl {
	margin-bottom: 70px;

	dt, dd {
		position: relative;
		border: 1px solid color('gray');
		border-left: 0;
		border-right: 0;
	}

	dt {
		font-size: 16px;
		font-weight: bold;
		text-transform: uppercase;
		padding: 20px;
		cursor: pointer;
		border-top: 0;

		.arrow {
			@include arrow($direction:bottom, $color: color('blue'), $width: 5px, $percentage: initial);
			position: absolute;
			top: 30px;
			right: 30px;
		    -webkit-transition: all 0.2s;
		    transition: all 0.2s;				
		}

		&:first-child {
			border-top: 1px solid color('gray');
		}		

		&.active {
			color: color('blue');
			border-bottom: 0;
			padding-bottom: 0;

			.arrow {
				transform: rotate(180deg);
				top: 24px;
				right: 20px;
			}

		}

	}

	dd {
		padding: 30px 20px;
		border-top: 0;
		border-bottom: 0;
		display: none;

		&.active {
			display: block;
			border-bottom: 1px solid color('gray');
		}

		li {
			font-size: 16px;
			line-height: 16px;
			padding: 15px 0;
			display: block;

			&:first-child {
				padding-top: 0;
			}

			&:last-child {
				padding-bottom: 0;
			}

		}

		.configurable-swatch-list {

			li {
				display: inline-block;
				vertical-align: top;
				padding: 10px;
			}

		}

	}

}

.left-cetegory {
	@include span-columns(12);
	margin: 0 0 100px 0;

	a.level0 {
		@include span-columns(12);
		font-size: 18px;
		font-weight: bold;
		text-transform: uppercase;
		padding: 26px 0;
		position: relative;
		background: color('gray', 'light');
		border: 1px solid color('gray', 'light');

		&:before,
		&:after {
			content: '';
			position: absolute;
			left: -1000%;
			height: 100%;
			width: 1000%;
			background: color('gray', 'light');
		}

		&:before {
			top: -1px;
		}

		&:after {
			bottom: -1px;
		}

	}

	ul.level0 {
		@include span-columns(12);
		padding: 30px 0;
		border-right: 1px solid color('gray', 'light');
		border-bottom: 1px solid color('gray', 'light');		
		position: relative;

		&:after {
			content: '';
			position: absolute;
			left: -1000%;
			height: 100%;
			width: 1000%;
			border-bottom: 1px solid color('gray', 'light');
		}

		&:after {
			bottom: -1px;
		}

		li {
			font-size: 18px;
			padding: 15px 0;
			display: block;
			line-height: 26px;

			&:first-child {
				padding-top: 0;
			}

			&:last-child {
				padding-bottom: 0;
			}

		}

	}

}