.modal {
    display: none;
    position: fixed;
    margin: 5% auto;
    z-index: 999999999;
    left: 0;
    right: 0;
    top: 0;
    width: 90%;
    background: color('white');
    padding: $gapp;

    .closed {
        position: absolute;
        right: 0;
        top: 0;
        padding: 10px;
        font-size: 20px;
        cursor: pointer;
        font-weight: bold;
        color: color('blue');
    }

    .title {
        display: block;
        margin-bottom: 20px;
        color: color('blue');
        width: 80%;
        font-weight: bold;
        font-size: 16px;
        text-transform: uppercase;        
    }

    .content {
        overflow-x: hidden;
        overflow-y: auto;
        height: 60%;
        overflow-wrap: break-word;
        line-height: 20px;
        font-size: 14px;  
        max-height: 450px;      
        padding-right: 20px;
    }

}