dl {
	text-align: center;

	dt {
		text-transform: uppercase;
		margin-bottom: 10px;
	}

	dt, dd {
		@include span-columns(12);		
	}

	dd {
		margin-bottom: 20px;

		#armored_website {
			width: 100% !important;

			img {
				margin: 0 auto;
			}

		}
		
	}

}

.payment {

	img {
		margin-bottom: 0;
	}

}