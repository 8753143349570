.flags {
	min-width: 30%;
	position: absolute;
	left: 0;
	top: 0;

	li {
		font-size: 10px;
		padding: 5px 10px;
		text-align: center;
		font-weight: bold;
		line-height: normal;

		&.blue {
			background: color('blue', 'light');
			color: color('white');
		}

		&.yellow {
			background: color('yellow');
			color: color('blue');
		}

	}

}