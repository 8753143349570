h1 {
    font-weight: bold;
    font-size: 22px;
    text-transform: uppercase;
}

h2 {
    font-size: 14px;
}

.page-title {
    @include span-columns(12);
    margin-top: 20px;

    h1, h2 {
        padding-bottom: 10px;
    }

}
