%item {
    @include omega(2n);
    @include span-columns(6);
    position: relative;
    margin-bottom: 20px;
    padding-top: 20px;
    text-align: center;
}

.products-grid {
    @include span-columns(12);
    margin-right: 0;

    .list-product--bundle {

        .price-box {

            .price-from {

                display: none;
            }
        }
    }

    .item {
        @extend %item;
        @import "../components/list/flags.scss";

        .product-image {
            margin-bottom: 10px;
            display: block;

            .hidden {
                @extend .hide;
            }

            img {
                margin: 0 auto;
                width: 100%;
            }

        }

        .products-info {
            line-height: 16px;
            font-size: 11px;

            .name {
                min-height: 35px;
            }

            .author {
                min-height: 20px;
                display: block;
            }

        }

        .price-box {
            margin-top: 5px;
            @import "../components/list/price.scss";
        }

    }

}
