#payment_form_webjump_braspag_dc {
    @include span-columns(12);

    li {

        &.card-number {
            margin-top: 20px;
        }

        &.card-code {

			.input-box {
				margin-bottom: 0;
				border-bottom: 1px solid color('gray', 'light');

				&:last-child {
					border-bottom: 0;
				}

				label {
					margin: $gapp;
					font-weight: normal;
                    margin-bottom: 10px;

					img {
						display: inline-block;
						margin-right: 10px;
						vertical-align: middle;
					}

				}			

			}

        }

        &.card-exp {
            @include span-columns(12);

            .v-fix {
                @include span-columns(6);

                select {
                    margin-bottom: 20px;
                }

            }

        }

        &.card-cvv {
            @include span-columns(12);
        }

    }

}