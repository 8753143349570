.ratings {

	.rating-box,
	.amount {
		display: inline-block;
	    vertical-align: middle;
	    line-height: initial;
	}

	.amount {
		margin-left: 10px;
	}

}

.rating-box {
    width: 80px;
    height: 12px;
    background: url(../images/rating-empty.png) 0 0 repeat-x;

    .rating {
        background: url(../images/rating.png) 0 0 repeat-x;
        height: 12px;
        display: block;
    }

}

.no-rating {
	font-size: 16px;
}

.form-add-review {
	@include span-columns(12);
	display: none;

	table {
		margin-bottom: 20px;
		@extend .vertical-scroll;

		thead {

			th {
				padding: 10px;
				text-align: center;
			}

		}

		tbody {

			th, td {
				padding: 10px 0;
				font-size: 14px;
			}

			td {
				text-align: center;				
			}

		}

	}

	.validation-advice {
		font-size: 12px;
		color: color('red');
		text-transform: uppercase;
		text-align: left;
	}

	.form-list {
		margin-top: 20px;

		em {
			@extend .no-display;
		}

	}

	.buttons-set {
		@include span-columns(12);
		text-align: center;	
	}

}