.sidebar {

	.block-title {
		font-size: 12px;
		font-weight: bold;
		text-transform: uppercase;
		padding: 20px;

		.closed {
			position: absolute;
			right: 0;
			top: 0;
		}
		
	}

	dl {

		dt {
			border-top: 1px solid color('gray');			
			color: color('blue');
			display: block;
			padding: 20px;			
			font-size: 16px;
			font-weight: bold;
			text-transform: uppercase;
			position: relative;

			.arrow {
				@include arrow($direction:top, $color: color('blue'), $width: 5px, $percentage: initial);
				position: absolute;
				top: 24px;
				right: 20px;
			    -webkit-transition: all 0.2s;
			    transition: all 0.2s;				
			}

			&.active {

				.arrow {
					transform: rotate(180deg);
					top: 24px;
					right: 10px;
				}

			}			

		}

		dd {
			padding: 0 20px 20px 20px;

			li {
				line-height: 20px;
				font-size: 16px;
				display: block;	
				margin: 10px 0;				
			}

		}

	}

}