.mini-banners {
	background: color('gray', 'light');

	ul {
		@include outer-container;
		padding: $gapp;

		li {
		    @include span-columns(12);
		    margin-bottom: $gapp;

		    img {
		    	width: 100%;
		    }

		}

	}

}