// grid
$column       : 70px;
$gutter       : 30px;
$grid-columns : 12;
$max-width    : 100%;
$gapp         : 10px;

// breakpoints
$desktop: new-breakpoint(max-width $max-width 12);

// typography
$font: 'Myriad Pro';
$font-bebas: 'Bebas Neue';
$font-size: 12px;
$fonticon: 'icomoon';