.col-1, .col-2 {
	@include span-columns(12);
	line-height: 18px;
	margin-top: 10px;
}

.count {
	display: inline-block;
	font-size: 14px;
	vertical-align: top;
}

.filter,
.sort-by {
	@include span-columns(6);
	font-size: 14px;
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 38px;
	height: 40px;
	border: 1px solid color('gray');
	color: color('gray','dark');
}

.sort-by {
	margin-right: 0;

	select {
	    margin: 0 auto;
	    width: auto;		
		line-height: 38px;
		padding: 0;	
		text-align: center;
		font-size: 14px;
		font-weight: bold;
		border: 0;
		text-transform: uppercase;
		color: color('gray','dark');	
		background: none;	
	}

}

.pages {
	text-align: center;
}

&.top {

	.pages {
		@extend .no-display;
	}

}

&.bottom {
	margin-bottom: 60px;

	.col-2,
	.sort-by,
	.filter {
		@extend .no-display;
	}

	.col-1 {
		@include span-columns(12);
	}

}