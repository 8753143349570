.address-book {
	@include span-columns(12);
	margin: 0 0 40px 0;	
	font-size: 16px;
	line-height: 20px;

	h3 {
		@include span-columns(8);
		color: color('gray', 'dark');

		& + p {
			@include span-columns(4);
			margin-right: 0;
			text-align: right;

			a {
				font-weight: bold;
				text-decoration: underline;
			}

		}

	}

	address {
		@include span-columns(12);
	}

	ul {
		@include span-columns(12);

		li {
			@include span-columns(12);
			border-bottom: 4px solid color('gray', 'light');
			margin: 0 0 20px 0;
			padding: 0 0 20px 0;
		}

		.change-address {
			@include span-columns(12);
			margin: 30px 0 0 0;

			a {
				padding-right: 10px;
				font-weight: bold;
				text-decoration: underline;					
			}

		}

	}

	.empty {

		p {
			@include span-columns(12);
		}
		
	}

}

&.customer-address-form {

	.content  {
		margin-top: 0;

		.buttons-set {
			@include span-columns(12);
			margin: 20px 0 0 0;

			.button {
				@include span-columns(12);
			}

		}

	}

	.control {

		p {
	        font-size: 16px;
	        line-height: 20px;  
		}

	}

}