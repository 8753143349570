body {
    font-family: $font;
    font-size: $font-size;
    color: color('gray', 'dark');

    &.overlay {
        position: fixed;
        overflow: hidden;    
        width: 100%;   

        &:before {
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow-y: scroll;
            background: rgba(color('black'), 0.8);
            width: 100%;
            height: 100%;
            z-index: 999999999;
        }

    }

}

.main-container {
    @include outer-container;

    .container, .main {
        @include span-columns(12);
        padding: 0 $gapp;
    }

}

a {
    text-decoration: none;
    color: color('gray', 'dark');
    @include transition(color, 0.2s);

    &:hover {
        color: color('blue', 'light');
    }

}

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

.no-display {
    display: none !important;
}

.hide {
    display: none;
}

.loader {
    background: url('../images/loader.gif') no-repeat center center;
    display: block;
    height: 50px;
    width: 50px;
    margin: 0 auto;    
}

.qty {
    @include span-columns(12);

    span, input {
        display: inline-block;
        text-align: center;
        vertical-align: middle;
    }

    span {
        height: 25px;
        width: 25px;
        line-height: 24px;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
        @include border-radius(50%, 50%, 50%, 50%);

        &.qty-left {
            color: color('blue');
            background: color('gray', 'light');
        }

        &.qty-right {
            color: color('white');
            background: color('blue');
        }

    }

    input {
        width: 55px;
        margin: 0 5px;
        font-size: 18px;
        color: color('gray');
        padding: 5px 10px;
    }

    &.dark {

        .qty-left {
            background: rgba(color('gray'), 0.2);
        }

    }

}

.vertical-scroll {
    @include span-columns(12);
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
}