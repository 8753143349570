.container {
	@include outer-container;
	padding: 30px;
	text-align: center;

	address {
		@include span-columns(12);
		font-size: 10px;
		line-height: 20px;
	}

	a {
		@include span-columns(12);

		img {
			margin: 15px auto 0 auto;
		}

	}

}