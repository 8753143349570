.god-with-us {

	.main-container {
    	@include outer-container(100%);
    	background: color('gray', 'light');

    	.container, .main {
    		@include span-columns(12);
    		padding: 0;
    	}

	}

	.page-title {
		@extend .no-display;
	}

	.std {
		@include span-columns(12);		
		background: color('white');
		text-align: center;
		padding: 0 $gapp;

		.sub-title {
			font-style: italic;

			.one {
				font-size: 12px;
				color: color('blue', 'dark');
			}
	
			.two {
				font-size: 16px;
				color: color('yellow', 'dark');					
			}

			.three {
				font-size: 20px;
				color: color('blue', 'dark');
			}

		}

		.god-slider {
			margin-top: 20px;

			.swiper-button-prev,
			.swiper-button-next {
				color: color('blue');
				border: 1px solid color('blue');
				background: color('white');

				&.swiper-button-disabled {
					display: none;
				}

			}

			.swiper-button-next {
				right: 0;
			}

			.swiper-button-prev {
				left: 0;
			}

			img {
				margin-bottom: 20px;
				width: 100%;
			}

			strong {
				font-family: $font-bebas;
				color: color('yellow', 'dark');					
				display: block;
				margin-bottom: 10px;
				font-size: 16px;
				text-transform: uppercase;
			}

			p {
				font-size: 12px;
				font-style: italic;
				line-height: 16px;

				strong {
					font-family: $font;
					color: initial;
					display: initial;
					margin: 0;
					font-size: 12px;
					text-transform: initial;
				}

			}

		}

	}

	&.discount-table {

		.std {
    		background: color('gray', 'light');
    		text-align: left;

    		table {
				@include span-columns(12);

    			tr {

    				td {
						@include span-columns(12);
						margin-bottom: $gapp;
						padding: $gapp;
    					background: color('white');
						text-align: center;

						&.sub-title {

							strong {
								font-size: 18px;
								color: color('blue', 'dark');
							}

						}

						&.left {
							font-style: italic;

							strong {
								display: block;
								padding-bottom: 10px;
								font-size: 18px;
								color: color('yellow', 'dark');	
							}

							p {
								font-size: 14px;
								line-height: 16px;
							}

						}

						&.box {
							position: relative;
							text-align: center;
							background: color('blue', 'dark');
							vertical-align: bottom;

							p {
								font-family: $font-bebas;
								font-size: 20px;
								text-transform: uppercase;
								color: color('white');
								padding-bottom: 10px;
							}

	    					a {
	    						width: 60%;
	    						padding: 10px 0;
	    						display: block;
	    						margin: 0 auto;
	    						text-transform: uppercase;
	    						text-align: center;
	    						background: color('white');
	    					}

						}

    				}

    			}

    		}

		}

	}

}