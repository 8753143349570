.esmart-contact-form {
	text-align: left;

	.form-list {
		margin: 20px 0;

		li {

			.required {
				@extend .no-display;
			}

		    .validation-advice {
		        @extend %advice-style;
		    }

		    .field {
				margin-bottom: 22px;

		    	&:nth-child(3),
		    	&:last-child {
		    		@include span-columns(12);
		    	}

		    	&:nth-child(5) {
		    		float: right;
		    		margin-right: 0;
		    	}

		    }

		    textarea {
		    	height: 100px;
		    }

		}

	}

}