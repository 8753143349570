.acordion {
	@include span-columns(12);

	dt, dd {
		@include span-columns(12);
	}

	dt {
		position: relative;
		font-size: 16px;
		text-transform: uppercase;
		color: color('gray');
		padding: 16px 0 16px 5px;
		border-bottom: 1px solid color('gray');

		&:first-child {
			border-top: 1px solid color('gray');
		}

		&:after {
			position: absolute;
		    right: 0;
		    top: 0;
		    padding: 18px;
		    font-size: 12px;
			font-family: 'icomoon';
			content: '\e90f';
	        -webkit-transition: all 0.2s;
	        transition: all 0.2s; 			
		}

		&.active {
			color: color('blue');
			border-color: color('blue');

			&:after {
				transform: rotate(180deg);
			}

		}

	}

	dd {
		display: none;
		padding: 20px 10px;
		font-size: 12px;
		line-height: 16px;
	}

}