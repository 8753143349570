.sidebar {
    float: left;
    width: 100%;
    height: 100%;
    position: fixed;
    left: -100%;
    top: 0;
    z-index: 999999999;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background: color('white');

    &.menu {
        width: 85%;
        left: -85%;
    }

}