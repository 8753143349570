.block-subscribe {
	@include span-columns(12);
	text-align: center;

	.block-title {
		display: block;
		font-size: 14px;
		text-transform: uppercase;
		margin-bottom: 22px;
	}

	.subscribe-header {
		@include span-columns(12);
		margin-bottom: 5px;
		line-height: 16px;
	}

	.validation-advice {
		text-align: left;
		color: color('white');
	}

	.input-box {

		&.input-name {		
			@include span-columns(12);
		}

		&.input-email {		
			@include span-columns(12);
		}

		label {
			display: block;
			margin-bottom: 8px;
			text-align: left;
		}

		input {
			border: 0;
			padding: 10px 11px;
			color: color('white');
			border-bottom: 1px solid color('white');
			background: rgba(color('white'), 0.1);
		}

	}

	.actions {
		@include span-columns(12);

		button {
			text-transform: uppercase;
			font-weight: normal;
			font-size: 14px;
		    padding: 11px;
		}

	}

}