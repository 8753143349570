.tooltip {
	position: relative;
	display: inline-block;
	vertical-align: bottom;
	text-align: center;
    margin-left: 10px;
    height: 16px;
    width: 16px;
    line-height: 16px;
    font-size: 10px;
    cursor: help;
	background: color('blue');
	color: color('white');
	@include border-radius(50%, 50%, 50%, 50%);

	p {
		@include fade();
		position: absolute;
		text-align: left;
		bottom: 30px;
		left: -12px;
		font-size: 12px;
		padding: 10px;
		color: color('white');		
		background: color('blue');
	    width: 180px;
	    word-break: break-all;

	    &:after {
			top: 100%;
			left: 10%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(136, 183, 213, 0);
			border-top-color: color('blue');
			border-width: 8px;
			margin-left: -8px;
	    }

	}

	&:hover {

		& > p {
			@include fade($type: in);
		}

	}

}