%step-active {
	color: color('blue', 'light');

	&:before {
		background: color('blue', 'light');
	}

}

.steps {
	@include span-columns(12);
	margin: 10px 0;

	ul {

		li {
			@include span-columns(3);	
			text-align: center;
			font-size: 12px;
			color: color('gray');

			span {
				font-size: 18px;
				display: block;
				margin: 0 0 10px 0;
			}
			
			&.checkout {
				@include span-columns(6);
				position: relative;

				&:before {
					content: '';
					position: absolute;
					top: 10px;
					left: -15%;
					width: 130%;
					height: 1px;
					background: color('gray');
				}

				span {
					background: color('white');
				    position: relative;
				    z-index: 1;
				    width: 50px;
				    margin: 0 auto 10px auto;					
				}

			}

		}

		&.active-cart {

			.cart {
				@extend %step-active;
			}

		}

		&.active-checkout {

			.cart,
			.checkout {
				@extend %step-active;
			}

		}

		&.active-success {

			.cart,
			.checkout,
			.success {
				@extend %step-active;
			}

		}		

	}

}