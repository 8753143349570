.catalogsearch-result-index {
	
	.block-layered-nav {
		@import "components/category/layered.scss";
	}

	.page-title {
		@include span-columns(12);
		padding: 10px 20px 10px 20px;
		border-bottom: 4px solid color('gray', 'light');

		h1 {
			font-size: 14px;
			font-weight: normal;
			text-transform: initial;
			padding-bottom: 0;

			strong {
				font-size: 16px;
				padding-left: 5px;
				font-weight: bold;
				text-transform: uppercase;
				color: color('blue');
			}

		}

	}

	.toolbar {
		@include span-columns(12);
		@import "components/category/toolbar.scss";
	}

	&.catalogsearch-result-index-empty {

	    .page-title {
	    	padding: 20px;

	    	p {
	    		display: block;
	    		padding-top: 20px;
	    		font-size: 14px;
	    	}

	    }

	    @import "components/home/showcase.scss";
	}

}