.menu {	

	p {
		font-size: 11px;
		text-transform: uppercase;
		padding-left: 3px;
		padding-top: 5px;
	}

	.icon, p {
		display: inline-block;
		vertical-align: middle;
	}

	.icon {
		width: 24px;
		height: 18px;
		margin: 15px 0;
		position: relative;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);

		span {
			display: block;
			position: absolute;
			height: 3px;
			width: 100%;
			background: color('white');
			opacity: 1;
			left: 0;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);

			&:nth-child(1) {
				top: 0px;
			}

			&:nth-child(2) {
				top: 8px;
			}

			&:nth-child(3) {
				top: 16px;
			}				

		}

		&.blue {

			span {
				background: color('blue');
			}

		}

	}

	&.active,
	&.closed {

		.icon {
			position: absolute;
			right: 12px;
			z-index: 999999999;
			padding-bottom: 200%;

			span {

				&:nth-child(1) {
					top: 8px;
					-webkit-transform: rotate(135deg);
					-moz-transform: rotate(135deg);
					-o-transform: rotate(135deg);
					transform: rotate(135deg);
				}

				&:nth-child(2) {
					opacity: 0;
					left: -60px;
				}

				&:nth-child(3) {
					top: 8px;
					-webkit-transform: rotate(-135deg);
					-moz-transform: rotate(-135deg);
					-o-transform: rotate(-135deg);
					transform: rotate(-135deg);
				}	

			}

		}

	}

}