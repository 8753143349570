.dashboard {
	@include span-columns(12);

	.content {
		margin-top: 0;
	}

	.welcome-msg {
		@include span-columns(12);
		margin: 0 0 20px 0;
		font-size: 16px;
		line-height: 20px;

		.hello {
			display: block;
			padding-bottom: 20px;
		}

	}

	.box-info {

		.change-password {
			display: block;
			padding-top: 30px;
			text-decoration: underline;
		}

	}

}