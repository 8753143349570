.cms-questions {

	.page-title {

		h1 {
			color: color('blue', 'light');
			margin: 0 0 10px 0;
			font-size: 18px;
		}

	}

	.std {
		@include span-columns(12);
		margin-bottom: 20px;

		dl {
			@include span-columns(12);

			dt {
				position: relative;
				cursor: pointer;
				margin-bottom: 15px;
				font-size: 18px;
				padding: 20px;
				background: color('gray', 'light');
				color: color('blue', 'light');
				@include arrow($direction:bottom, $color: color('blue'), $width: 5px, $percentage: initial);

				&:before {
					content: '';
					position: absolute;
					top: 28px;
					right: 20px;
				    -webkit-transition: all 0.2s;
				    transition: all 0.2s;				
				}

				&.active {

					&:before {
						transform: rotate(180deg);
					}	

				}

			}

			dd {
				padding: 0 20px 20px 20px;
				font-size: 16px;
				line-height: 20px;
				display: none;
			}

		}

	}

	.footer-sac {
		@include span-columns(12);
		margin-bottom: 20px;			
		
		h2 {
			font-size: 18px;
			font-weight: bold;
			text-transform: uppercase;
		}

		li {
			@include span-columns(12);
			padding: 40px 0;
			text-align: center;
			border-bottom: 1px solid color('gray', 'light');

			&:last-child {
				border-bottom: 0;
			}

			span, strong {
				color: color('blue', 'light');
			}

			span {
				display: block;
				font-size: 60px;
				margin-bottom: 20px;
			}

			strong {
				font-size: 18px;
				line-height: 30px;
				font-weight: bold;
			}

			p {
				font-size: 16px;
			}

		}

	}

}