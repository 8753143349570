.page-header {
	@include span-columns(12);
	background: color('blue');
	color: color('white');

	.header-menu {
		@include span-columns(2.5, block-collapse);
		padding-left: $gapp;		
	}

	.header-logo {
		@include span-columns(7, block-collapse);
		text-align: center;
		padding-right: $gapp;

		img {
			margin: 7px auto;
			width: 110px;
		}

	}

	.header-cart {
		@include span-columns(2.5, block-collapse);
		float: right;
		@import "components/header/cart.scss";
	}

	&.onepage {
		@import "components/header/onepage.scss";
	}

}

.header-links {
	@include span-columns(12);
	@import "components/header/links.scss";
}

.header-search {
	@include span-columns(12);
	@import "components/header/search.scss";
}

.header-nav {
	@include span-columns(12);
	@import "components/header/nav.scss";
}

@import "components/header/menu.scss";
@import "components/header/info-account.scss";