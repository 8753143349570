.catalog-category-view {

	.breadcrumbs {
		padding: 22px $gapp;
	}

	.category-products {
		@include span-columns(12);
		padding-top: 30px;
		margin-top: 10px;
		border-top: 4px solid color('gray', 'light');
	}

	.category-title {
		display: inline-block;
		vertical-align: top;

		h1 {
			font-size: 16px;
			font-weight: bold;
			color: color('blue');
			text-transform: initial;
		}

	}

	.block-layered-nav {
		@import "components/category/layered.scss";
	}

	.toolbar {
		@include span-columns(12);
		@import "components/category/toolbar.scss";
	}

}